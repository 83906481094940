import type { SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import type { FloatingLabelInputProps } from "@aviary";
import { EmailInput } from "@shared/components/EmailInput/EmailInput";
import { isEmailDotValid } from "@shared/components/EmailInput/EmailValidation/EmailValidation";
import { useGlobalConfig } from "@unauthenticated/shared/context/GlobalConfig/GlobalConfig";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

interface Props extends Omit<FloatingLabelInputProps, "id" | "label" | "type"> {
  isLoading?: boolean;
  value: string;
  isDirty: boolean;
  setEmail: (value: SetStateAction<string>) => void;
}

const PatientCheckEmailInput = ({ setEmail, ...rest }: Props) => {
  const { t } = useTranslation();
  const { emailRegex } = useGlobalConfig();

  const onChange = e => {
    rest.onChange?.(e);
  };

  const getError = () => {
    if (!isEmailDotValid(rest.value)) {
      return t(l.common.EmailMustHaveDot);
    }
    if (!emailRegex.test(rest.value)) {
      return t(l.common.EmailInvalidFormat);
    }
  };

  return <EmailInput error={getError()} {...rest} setEmail={setEmail} onChange={onChange} />;
};

export { PatientCheckEmailInput };
