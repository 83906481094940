import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const PATIENT_INVITATION_CHECK_MUTATION = gql`
  mutation PatientInvitationCheck_UnauthenticatedSignUpSignIn_Mutation(
    $input: AuthPatientInvitationCheckInput!
  ) {
    auth {
      patientInvitationCheck(input: $input) {
        accountExists
        errors {
          message
          fields {
            email
          }
        }
      }
    }
  }
`;

interface AuthPractitionerSignUpFieldError {
  email: string[];
}

interface AuthPractitionerSignUpError {
  fields: AuthPractitionerSignUpFieldError;
  message: string;
}

interface PatientInvitationCheckMutationData {
  auth: {
    patientInvitationCheck: {
      accountExists: boolean;
      errors: AuthPractitionerSignUpError;
    };
  };
}

interface AuthPatientInvitationCheckInput {
  email: string;
  signUpPage: string;
  pageVersion: string;
}

interface Variables {
  input: {
    captchaToken: string;
    attributes: AuthPatientInvitationCheckInput;
  };
}

const usePatientInvitationCheckMutation = (
  options?: MutationHookOptions<PatientInvitationCheckMutationData, Variables>
) =>
  useMutation<PatientInvitationCheckMutationData, Variables>(
    PATIENT_INVITATION_CHECK_MUTATION,
    options
  );

export type { PatientInvitationCheckMutationData };
export { usePatientInvitationCheckMutation, PATIENT_INVITATION_CHECK_MUTATION };
