import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

import type { PractitionerRequestSharedAccountVerificationEmailInput } from "@shared/types/graphqlGenerated";

const RequestSharedAccountVerificationEmail_Mutation = gql`
  mutation RequestSharedAccountVerificationEmail_UnauthenticatedSignUpSignIn_Mutation(
    $input: PractitionerRequestSharedAccountVerificationEmailInput!
  ) {
    auth {
      requestSharedAccountVerificationEmail(input: $input) {
        sent
        errors {
          message
        }
      }
    }
  }
`;

interface Variables {
  input: PractitionerRequestSharedAccountVerificationEmailInput;
}

interface RequestSharedAccountVerificationEmailData {
  auth: {
    requestSharedAccountVerificationEmail: {
      sent: boolean;
      errors: {
        message: string;
      };
    };
  };
}

interface RequestSharedAccountVerificationEmailError {
  message: string;
}

const useRequestSharedAccountVerification = (
  options?: MutationHookOptions<RequestSharedAccountVerificationEmailData, Variables>
) => useMutation(RequestSharedAccountVerificationEmail_Mutation, options);

export type {
  RequestSharedAccountVerificationEmailData,
  RequestSharedAccountVerificationEmailError,
};
export { useRequestSharedAccountVerification, RequestSharedAccountVerificationEmail_Mutation };
