import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

import type { AuthPatientNextLoginStepInput } from "@shared/types/graphqlGenerated";

const PatientNextLoginStep_UnauthenticatedSignUpSignIn_Mutation = gql`
  mutation PatientNextLoginStep_UnauthenticatedSignUpSignIn_Mutation(
    $input: AuthPatientNextLoginStepInput!
  ) {
    auth {
      patientNextLoginStep(input: $input) {
        nextStep
      }
    }
  }
`;

interface PatientNextLoginStep {
  auth: {
    patientNextLoginStep: {
      nextStep: string;
      errors: {
        fields: { email?: string };
        message?: string;
      };
    };
  };
}

type Variables = {
  input: AuthPatientNextLoginStepInput;
};

const usePatientNextLoginStep = (options?: MutationHookOptions<PatientNextLoginStep, Variables>) =>
  useMutation<PatientNextLoginStep, Variables>(
    PatientNextLoginStep_UnauthenticatedSignUpSignIn_Mutation,
    options
  );

export type { Variables, PatientNextLoginStep };

export { PatientNextLoginStep_UnauthenticatedSignUpSignIn_Mutation, usePatientNextLoginStep };
