import { useSearchParams, Navigate } from "@shared/react-router-dom/react-router-dom";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import type { PatientAttributes } from "@unauthenticated/shared/data/types/PatientAttributes.d";

interface Props {
  isStoreOwner: boolean;
  isPreview: boolean;
  patientAttributes: PatientAttributes;
}

const PatientIntakePage = ({ patientAttributes }: Props) => {
  const { storeSlug } = patientAttributes;
  const [searchParams] = useSearchParams();
  const practitionerId = searchParams.get("requestedPractitionerId");

  return (
    <Navigate
      to={`${authRoutes.welcomeSignUp(
        storeSlug
      )}?intake=true&requestedPractitionerId=${practitionerId}`}
    />
  );
};

export { PatientIntakePage };
