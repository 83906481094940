import { css, type Theme } from "@emotion/react";

import * as dimensions from "@styles/emotion-styles/dimensions";

export const textWrapper = css`
  text-align: center;

  @media (min-width: ${dimensions.tablet}) {
    margin-top: 1rem;
  }
`;

export const descriptionWrapper = css`
  margin: 2rem 0;
`;

export const emailInput = css`
  max-width: 26rem;
  margin: 1.5rem auto;

  @media (max-width: ${dimensions.phone}) {
    max-width: 100%;
  }
`;

export const chevron = (theme: Theme) => css`
  font-size: ${theme.aviaryTypography.caption.fontSize};
  margin-right: 0.5rem;
`;

export const errorMessage = css`
  width: 100%;
  max-width: 42rem;
`;
