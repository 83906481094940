import { css, type Theme } from "@emotion/react";

export const errorBoundary = css`
  display: flex;
  justify-content: center;
  padding: 3.75rem 0 0 0;
`;

export const content = css`
  padding: 0 1rem;
`;

export const title = (theme: Theme) => css`
  color: ${theme.success.textBase};
`;

export const subTitleContainer = css`
  display: flex;
  align-items: center;
  margin: 0 0 0.75rem 0;
`;

export const subTitle = (theme: Theme) => css`
  color: ${theme.success.textBase};
`;

export const text = (theme: Theme) => css`
  font-size: 1rem;
  max-width: 27rem;
  margin-bottom: 1rem;
  line-height: 1.75rem;
  color: ${theme.text.body};
`;

export const logo = css`
  height: 1.375rem;
  width: auto;
  margin: 0 0.625rem 0 0;
`;
