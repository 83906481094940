import { css, type Theme } from "@emotion/react";

export const dropDown = css`
  width: 100%;
`;

export const searchBarInput = (theme: Theme) => css`
  background-color: ${theme.input.backgroundBase};
`;

export const searchBarBox = (theme: Theme) => css`
  background-color: ${theme.input.backgroundBase};
  border-bottom: 1px solid ${theme.input.borderEmphasized};

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  :hover,
  :active,
  :focus-within,
  :focus {
    background-color: ${theme.input.backgroundHover};
    box-shadow: none;
    transition-delay: -1s;

    > input {
      background-color: ${theme.input.backgroundHover};
      transition-delay: -1s;
    }
  }
`;
