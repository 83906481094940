import { Spacer, Skeleton } from "@aviary";

import * as styles from "./GoogleSSOButton.styles";

interface Props {
  isOrTextRendered?: boolean;
}
const SkeletonGoogleSSOButton = ({ isOrTextRendered }: Props) => {
  const skeletonOr = (
    <div css={styles.GoogleSSOButton}>
      <Skeleton type="typography" typographySize="footnote" width={4} />
      <Spacer width="half" />
      <Skeleton type="button" width={1} height={1} />
      <Spacer width="half" />
      <Skeleton type="typography" typographySize="footnote" width={4} />
    </div>
  );

  if (isOrTextRendered) {
    return (
      <>
        <Spacer height="one" />
        <Skeleton type="button" buttonSize="medium" width={10} />
        <Spacer height="one" />
        {skeletonOr}
        <Spacer height="one" />
      </>
    );
  }
};

export { SkeletonGoogleSSOButton };
