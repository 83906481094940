import { Suspense, lazy } from "react";

import { SkeletonEditPasswordPage } from "./SkeletonEditPasswordPage";
const EditPasswordPage = lazy(() => import("./EditPasswordPageDefault"));

const LazyEditPasswordPage = () => {
  return (
    <Suspense fallback={<SkeletonEditPasswordPage />}>
      <EditPasswordPage />
    </Suspense>
  );
};

export { LazyEditPasswordPage };
