import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const OFFICE_SIGN_UP_LOGGER_MUTATION = gql`
  mutation OfficeSignUpLogger_UnauthenticatedSignUpSignIn_Mutation(
    $input: AuthLoggersOfficeSignUpInput!
  ) {
    auth {
      officeSignUpLogger(input: $input) {
        clientMutationId
        errors {
          message
        }
      }
    }
  }
`;

interface AuthLoggersOfficeSignUpError {
  message: string;
}

interface OfficeSignUpLoggerData {
  auth: {
    officeSignUpLogger: {
      clientMutationId: string;
      errors: AuthLoggersOfficeSignUpError;
    };
  };
}

interface AuthLoggersOfficeSignUpAttributes {
  eventType: string;
  eventAggregateId: string;
  signUpPage: string;
  pageVersion: string;
  field?: string;
}

interface Variables {
  input: {
    attributes: AuthLoggersOfficeSignUpAttributes;
  };
}

const useOfficeSignUpLoggerMutation = (
  options?: MutationHookOptions<OfficeSignUpLoggerData, Variables>
) => useMutation<OfficeSignUpLoggerData, Variables>(OFFICE_SIGN_UP_LOGGER_MUTATION, options);

export type { OfficeSignUpLoggerData };
export { useOfficeSignUpLoggerMutation, OFFICE_SIGN_UP_LOGGER_MUTATION };
