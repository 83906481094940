import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const stepper = css`
  @media (min-width: ${dimensions.desktop}) {
    padding-left: 18rem;
    padding-right: 18rem;
  }
`;
