import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

import type {
  AuthMutationPatientSignUpArgs,
  AuthPatientSignUpError,
  Patient,
} from "@shared/types/graphqlGenerated";

const PATIENT_SIGN_UP_MUTATION = gql`
  mutation PatientSignUp_UnauthenticatedShared_Mutation($input: AuthPatientSignUpInput!) {
    auth {
      patientSignUp(input: $input) {
        patient {
          id
        }
        redirectPath
        errors {
          message
          fields {
            email
            firstName
            lastName
            password
            storeId
          }
        }
      }
    }
  }
`;

interface PatientSignUpMutationData {
  auth: {
    patientSignUp: {
      patient: Pick<Patient, "id">;
      redirectPath?: string;
      errors: AuthPatientSignUpError;
    };
  };
}

const usePatientSignUpMutation = (
  options?: MutationHookOptions<PatientSignUpMutationData, AuthMutationPatientSignUpArgs>
) =>
  useMutation<PatientSignUpMutationData, AuthMutationPatientSignUpArgs>(
    PATIENT_SIGN_UP_MUTATION,
    options
  );

export type { PatientSignUpMutationData };
export { usePatientSignUpMutation, PATIENT_SIGN_UP_MUTATION };
