import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

import type {
  AuthUserSendMagicLinkError,
  AuthUserSendMagicLinkInput,
} from "@shared/types/graphqlGenerated";

const UserSendMagicLink_UnauthenticatedShared_Mutation = gql`
  mutation UserSendMagicLink_UnauthenticatedShared_Mutation($input: AuthUserSendMagicLinkInput!) {
    auth {
      userSendMagicLink(input: $input) {
        errors {
          message
        }
      }
    }
  }
`;

interface UserSendMagicLinkMutationData {
  auth: {
    userSendMagicLink: {
      errors: AuthUserSendMagicLinkError;
    };
  };
}

interface Variables {
  input: AuthUserSendMagicLinkInput;
}

const useUserSendMagicLinkMutation = (
  options?: MutationHookOptions<UserSendMagicLinkMutationData, Variables>
) =>
  useMutation<UserSendMagicLinkMutationData, Variables>(
    UserSendMagicLink_UnauthenticatedShared_Mutation,
    {
      ...options,
    }
  );

export type { UserSendMagicLinkMutationData };
export { useUserSendMagicLinkMutation, UserSendMagicLink_UnauthenticatedShared_Mutation };
