import { withProfiler } from "@sentry/react";
import { StrictMode } from "react";

import { LazyHotReloadIndicator } from "@shared/components/LazyHotReloadIndicator/LazyHotReloadIndicator";
import { LazyReactAxe } from "@shared/components/LazyReactAxe/LazyReactAxe";
import { Router } from "@shared/components/Router/Router";
import type { Flipper } from "@shared/types/flipper";
import { ErrorBoundary } from "@unauthenticated/signupSignin/components/ErrorBoundary/ErrorBoundary";
import { Providers } from "@unauthenticated/signupSignin/components/Providers/Providers";
import { OfficeSignUpPage as OfficeSignUpScene } from "@unauthenticated/signupSignin/scenes/OfficeSignUpPage/OfficeSignUpPage";

interface Props {
  currentCountry: string;
  flippers: Flipper[];
  ssoErrors?: string;
  roleId: string;
  roleType: "Practitioner" | "Clerk";
  roleAttributes?: any;
  storeId: string;
  e2e: boolean;
  analyticsAnonymousId: string;
  isEmersonUser?: boolean;
}

const OfficeSignUpPage = ({
  currentCountry,
  flippers,
  ssoErrors,
  roleId,
  roleType,
  roleAttributes,
  storeId,
  e2e,
  analyticsAnonymousId,
  isEmersonUser,
}: Props) => {
  return (
    <StrictMode>
      <Router>
        <Providers
          currentCountry={currentCountry}
          flippers={flippers}
          e2e={e2e}
          analyticsAnonymousId={analyticsAnonymousId}
        >
          <ErrorBoundary {...{ flippers, roleId, storeId, roleType }}>
            <OfficeSignUpScene
              roleAttributes={roleAttributes}
              roleType={roleType}
              roleId={roleId}
              ssoErrors={ssoErrors}
              isEmersonUser={isEmersonUser}
            />
          </ErrorBoundary>
        </Providers>
        <LazyHotReloadIndicator />
        <LazyReactAxe />
      </Router>
    </StrictMode>
  );
};

const PatientInvitationPageWithProfiler = withProfiler(OfficeSignUpPage);
export { PatientInvitationPageWithProfiler as OfficeSignUpPage };
