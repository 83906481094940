import { css, type Theme } from "@emotion/react";

export const textWrapper = css`
  margin-bottom: 3rem;
  max-width: 42rem;
  text-align: center;
  overflow-wrap: anywhere;
`;

export const chevron = (theme: Theme) => css`
  font-size: ${theme.aviaryTypography.caption.fontSize};
  margin-right: 0.5rem;
`;
