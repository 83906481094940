import { Skeleton, Spacer } from "@aviary";
import { SignInWrapper } from "@unauthenticated/shared/components/SignInWrapper/SignInWrapper";
import { SkeletonForgotPasswordForm } from "@unauthenticated/signupSignin/components/ForgotPasswordForm/SkeletonForgotPasswordForm";
import { SkeletonReturnToSignInButton } from "@unauthenticated/signupSignin/components/ReturnToSignInButton/SkeletonReturnToSignInButton";
import { SignInLayout } from "@unauthenticated/signupSignin/components/SignInLayout/SignInLayout";

const SkeletonForgotPasswordPage = () => {
  return (
    <SignInLayout>
      <SignInWrapper>
        <Skeleton type="typography" typographySize="h1" margin="bottom" />
        <Spacer height="half" />
        <Skeleton type="typography" typographySize="body" isFullWidth margin="topAndBottom" />
        <Spacer height="half" />
        <Skeleton type="typography" typographySize="body" />
        <SkeletonForgotPasswordForm />
        <SkeletonReturnToSignInButton />
      </SignInWrapper>
    </SignInLayout>
  );
};

export { SkeletonForgotPasswordPage };
