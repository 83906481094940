import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Arrange, Avatar, Typography } from "@aviary";
import FullscriptLogoGreenLeafPigeonTextSVGComponent from "@shared/assets/logo/FullscriptLogoGreenLeafPigeonText";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./PatientInfoBlock.styles";

interface Props {
  storeName: string;
  pracAvatar?: string;
  children: ReactNode;
}

const PatientInfoBlock = ({ children, storeName, pracAvatar }: Props) => {
  const { t } = useTranslation();

  return (
    <Arrange isVertical justify="center" alignment="center" css={styles.fullHeight}>
      <FullscriptLogoGreenLeafPigeonTextSVGComponent css={styles.svgContainer} />
      {!!pracAvatar && (
        <Avatar
          image={pracAvatar}
          label={t(l.patientRxAuth.storeLandingPages.leftColumn.pracAvatar, {
            storeName,
            interpolation: { escapeValue: false },
          })}
          size="large"
        />
      )}
      <Typography type="h4">
        {t(l.patientRxAuth.storeLandingPages.leftColumn.tips, {
          storeName,
          interpolation: { escapeValue: false },
        })}
      </Typography>
      {children}
    </Arrange>
  );
};

export { PatientInfoBlock };
