import type { SyntheticEvent, ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@aviary";
import { e2eAttributes } from "@shared/e2eAttributes";
import { PasswordInput } from "@unauthenticated/shared/components/PasswordInput/PasswordInput";
import { isPasswordValid } from "@unauthenticated/shared/components/PasswordInput/PasswordValidation/PasswordValidation";
import { TermsOfService } from "@unauthenticated/shared/components/TermsOfService/TermsOfService";
import { l } from "@unauthenticated/signupSignin/locales/i18n";
import { useSubmitOfficeSignUp } from "@unauthenticated/signupSignin/scenes/OfficeSignUpPage/useSubmitOfficeSignUp/useSubmitOfficeSignUp";

import * as styles from "./OfficeSignUpForm.styles";
interface Props {
  invitationToken: string;
  logField: (field: string) => void;
  setSignUpError: (error: string) => void;
}

const OfficeSignUpForm = ({ logField, invitationToken, setSignUpError }: Props) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [commonPasswordError, setCommonPasswordError] = useState([]);

  const isFormValid = isPasswordValid(password);

  const { onFormSubmit, loading, data } = useSubmitOfficeSignUp({ setSignUpError });

  useEffect(() => {
    if (data) setCommonPasswordError(data?.auth?.officeSignUp?.errors?.fields?.password);
  }, [data]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setCommonPasswordError([]);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isFormValid) {
      setPasswordDirty(true);

      return;
    }

    onFormSubmit({ password, invitationToken });
  };

  return (
    <form onSubmit={handleSubmit} css={styles.form} noValidate>
      <PasswordInput
        value={password}
        onChange={handleOnChange}
        wrapperStyles={styles.marginBottom}
        required
        isDirty={passwordDirty}
        onFocus={() => setPasswordDirty(true)}
        onBlur={() => {
          if (isPasswordValid(password)) {
            logField("password");
          }
        }}
        label={t(l.officeSignUp.CreatePassword)}
        errors={commonPasswordError}
        isLoading={loading}
        data-e2e={e2eAttributes.officeSignUp.password}
      />
      <TermsOfService css={styles.serviceTerms} />
      <Button
        type="submit"
        disabled={!isFormValid}
        isLoading={loading}
        data-e2e={e2eAttributes.officeSignUp.button}
      >
        {t(l.officeSignUp.ActivateAccount)}
      </Button>
    </form>
  );
};

export { OfficeSignUpForm };
