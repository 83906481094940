import type { HTMLProps, ReactNode } from "react";

import { Typography } from "../Typography";

import * as styles from "./Separator.styles";

interface Props extends HTMLProps<HTMLHRElement> {
  /**
   * Pass in text that will be rendered in the center of the
   * separator line
   *
   */
  text?: ReactNode;
  /**
   * Adds in negative margin when used within a Box
   * to make it go fullwidth
   *
   * @default false
   */
  isBoxed?: boolean;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/design-tokens/colour-tokens/Separator
 */
const Separator = ({ text, isBoxed, ...rest }: Props) => {
  const separatorStyles = [styles.separator, isBoxed && styles.withinBox];

  if (text) {
    return (
      <div css={styles.labelSeparator} data-testid="separator" {...rest}>
        <Typography isSubdued type="footnote" css={styles.label}>
          {text}
        </Typography>
      </div>
    );
  }

  return <hr data-testid="separator" css={separatorStyles} {...rest} />;
};

export { Separator };
