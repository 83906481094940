import { css, type Theme } from "@emotion/react";

import { animations, helpers, dimensions } from "@styles";

export const informationArea = {
  base: (theme: Theme) => css`
    padding: 0.5rem 1rem;
    border: 1px solid ${theme.input.borderBase};
    border-top: none;
    border-radius: 0 0 ${dimensions.borderRadius} ${dimensions.borderRadius};
    font-size: 0.875rem;
    background-color: ${theme.input.backgroundInformation};
  `,
  errorStyles: (theme: Theme) => css`
    border-color: ${theme.input.textInformationError};
    align-items: center;
  `,
  disabled: (theme: Theme) => css`
    color: ${theme.disabled.inputLabelText};
    border-color: ${theme.disabled.border};
    cursor: not-allowed;
  `,
};

export const errorText = (theme: Theme) => css`
  ${helpers.fontSizeNormalizer(14)};
  color: ${theme.input.textInformationError};
  animation: ${animations.fadeIn} 0.25s ${animations.easeOutCirc};
  &:not(:last-of-type) {
    margin-bottom: 0.25rem;
  }
`;

export const errorMessageWrapper = css`
  display: flex;
`;

export const errorIcon = (theme: Theme) => css`
  color: ${theme.input.textInformationError};
  margin-right: 0.5rem;
`;
