import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const PATIENT_SIGN_UP_LOGGER_MUTATION = gql`
  mutation PatientSignUpLogger_UnauthenticatedSignUpSignIn_Mutation(
    $input: AuthLoggersPatientSignUpInput!
  ) {
    auth {
      patientSignUpLogger(input: $input) {
        errors {
          message
        }
      }
    }
  }
`;

interface AuthLoggersPatientSignUpError {
  message: string;
}

interface PatientSignUpLoggerData {
  auth: {
    practitionerSignUpLogger: {
      errors: AuthLoggersPatientSignUpError;
    };
  };
}

interface AuthLoggersPatientSignUpAttributes {
  eventType: string;
  eventAggregateId: string;
  signUpPage: string;
  pageVersion: string;
  field?: string;
}

interface Variables {
  input: {
    attributes: AuthLoggersPatientSignUpAttributes;
  };
}

const usePatientSignUpLoggerMutation = (
  options?: MutationHookOptions<PatientSignUpLoggerData, Variables>
) => useMutation<PatientSignUpLoggerData, Variables>(PATIENT_SIGN_UP_LOGGER_MUTATION, options);

export type { PatientSignUpLoggerData };
export { usePatientSignUpLoggerMutation, PATIENT_SIGN_UP_LOGGER_MUTATION };
