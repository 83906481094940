/* eslint-disable no-restricted-imports, @fullscript/cross-reference */
import * as remix from "@remix-run/react";
import * as router from "react-router-dom";

/**
 * A ScrollRestoration component wrapping ScrollRestoration from Remix and react-router-dom
 * It can be used in interchangeably in a Remix or react-router-dom based SPA and work as expected
 * Means that devs don't need to worry about where an app is running and what API to use
 */
const ScrollRestoration = (props: router.ScrollRestorationProps) => {
  let isRouter = true;

  try {
    router.useLocation();
  } catch (e) {
    isRouter = false;
  }

  if (isRouter) {
    return <router.ScrollRestoration {...props} />;
  }

  return <remix.ScrollRestoration {...props} />;
};

export { ScrollRestoration };
