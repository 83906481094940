import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";

import * as animations from "@styles/emotion-styles/animations";
import * as dimensions from "@styles/emotion-styles/dimensions";
import * as timing from "@styles/emotion-styles/timing";

export const notificationWrapper = css`
  text-align: center;
`;

export const noStyleLink = (theme: Theme) => css`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  color: ${theme.text.body};
  margin: 0 auto;
  @supports (width: fit-content) {
    width: fit-content;
  }

  &:active {
    .icon-chevron {
      margin-right: 2rem;
    }
  }
`;

export const bodySpacing = css`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const inlineLink = (theme: Theme) => css`
  color: ${theme.text.body};
`;

export const dualLogoIcon = css`
  height: 5.4rem;
`;
export const semibold = css`
  font-weight: ${typography.weightStrong};
  @media only screen and (max-width: ${dimensions.tabletMax}) {
    font-weight: ${typography.weightBase};
  }
`;

export const chevron = (theme: Theme) => css`
  margin-right: 1rem;
  transition: margin ${timing.fast} ${animations.easeOutCubic};
  color: ${theme.success.textBase};
`;
