import { css } from "@emotion/react";

import { hoverBoxShadow } from "@styles/emotion-styles/animations";
import * as colors from "@styles/emotion-styles/colors";
import { borderRadius } from "@styles/emotion-styles/dimensions";

export const boxShadowMaker = css`
  background: ${colors.white};
  border: 1px solid ${colors.grey.lightFamily.dark};
  box-sizing: border-box;
  border-radius: ${borderRadius};

  :hover,
  :active,
  :focus-within,
  :focus {
    ${hoverBoxShadow}
  }
`;
