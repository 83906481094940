import { faUser, faStethoscope } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Arrange, Box, Button, CircularIcon, Columns, Typography } from "@aviary";
import { useFlippers } from "@shared/context/FlippersContext";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "@shared/react-router-dom/react-router-dom";
import { l } from "@unauthenticated/shared/locales/i18n";

import { RoleTile } from "./RoleTile";

import * as styles from "./SignUpTriage.styles";

const SignUpTriage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tablet } = useBreakpoints();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSonicSegmentProvidersEnabled] = useFlippers("sonic_segment_providers");
  const [isPractitioner, setIsPractitioner] = useState(true);
  const { search } = useLocation();

  const setRoute = () => {
    if (isPractitioner) {
      pracSignUp();
    } else {
      navigate(`/patient-signup${search}`);
    }
  };

  const pracSignUp = () => {
    searchParams.set("step", "1");
    setSearchParams(searchParams);
  };

  const renderHeader = () => {
    if (isSonicSegmentProvidersEnabled) {
      return (
        <>
          <Typography type="h2">{t(l.common.GetStartedWithFs)}</Typography>
          <Typography isMarginless>{t(l.common.SelectYourAccountType)}</Typography>
        </>
      );
    }

    return (
      <>
        <Typography type="h2">{t(l.common.GetStartedWithFs)}</Typography>
        <Typography isMarginless>{t(l.common.WhichTypeOfAccount)}</Typography>
      </>
    );
  };

  const renderTiles = () => {
    if (isSonicSegmentProvidersEnabled) {
      return (
        <>
          <Box css={styles.boxTile} onClick={() => pracSignUp()}>
            <Arrange justify="center" alignment="center" isVertical>
              <CircularIcon size="large" icon={faStethoscope} />
              <Typography type="h4">{t(l.common.PracAcccount)}</Typography>
              <Typography>{t(l.common.RecommendSupplementsAndLabs)}</Typography>
            </Arrange>
          </Box>

          <Box css={styles.boxTile} onClick={() => navigate(`/patient-signup${search}`)}>
            <Arrange justify="center" alignment="center" isVertical>
              <CircularIcon size="large" icon={faUser} />
              <Typography type="h4">{t(l.common.PatientAccount)}</Typography>
              <Typography>{t(l.common.OrderSupplementsAndLabs)}</Typography>
            </Arrange>
          </Box>
        </>
      );
    }

    return (
      <>
        <RoleTile
          name={t(l.common.PracAcccount)}
          icon={faStethoscope}
          isSelected={isPractitioner}
          onSelected={() => setIsPractitioner(true)}
          isFullHeight={tablet.lessThan}
        />
        <RoleTile
          name={t(l.common.PatientAccount)}
          icon={faUser}
          isSelected={!isPractitioner}
          onSelected={() => setIsPractitioner(false)}
          isFullHeight={tablet.lessThan}
        />
      </>
    );
  };

  return (
    <div css={styles.contentBox}>
      {renderHeader()}
      <form onSubmit={setRoute}>
        <Columns css={styles.tilesContainter} preserveMobileColumns>
          {renderTiles()}
        </Columns>
        {!isSonicSegmentProvidersEnabled && (
          <Button
            type="submit"
            onClick={setRoute}
            isFullWidth={tablet.lessThan}
            data-e2e="role-selection-button"
          >
            {t(l.common.Next)}
          </Button>
        )}
      </form>
    </div>
  );
};

export { SignUpTriage };
