import { css, type Theme } from "@emotion/react";

export const wrapper = css`
  text-align: left;
  padding: 0 0.5rem;
`;

export const stepText = (theme: Theme) => css`
  color: ${theme.text.body};
`;
