import type { ReactNode } from "react";

import { Column, Columns, Spacer } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { FooterLogos } from "@unauthenticated/signupSignin/scenes/PractitionerSignUpPage/AuthFooter/FooterLogos/FooterLogos";
import { FooterPrivacy } from "@unauthenticated/signupSignin/scenes/PractitionerSignUpPage/AuthFooter/FooterPrivacy/FooterPrivacy";

import * as styles from "./AuthFooter.styles";

interface Props {
  children?: ReactNode;
}

const AuthFooter = ({ children }: Props) => {
  const { tablet } = useBreakpoints();

  const renderContent = () => {
    if (tablet.greaterThan) {
      return (
        <>
          <Column columnWidth={3}>
            <FooterLogos />
          </Column>
          <Column textAlign="center">
            {children}
            <FooterPrivacy />
          </Column>
          <Column columnWidth={3} />
        </>
      );
    }

    return (
      <Column css={styles.mobileWrapper} textAlign="center" columnWidth="narrow">
        {children}
        <FooterLogos />
        <Spacer height="oneHalf" />
        <FooterPrivacy />
      </Column>
    );
  };

  return (
    <footer css={[styles.footer, tablet.greaterThan && styles.topBorder]}>
      <Columns css={styles.wrapper} isMarginless isMultiline>
        {renderContent()}
      </Columns>
    </footer>
  );
};

export { AuthFooter };
