import { css, type Theme } from "@emotion/react";

import { animations, helpers } from "@styles";

export const informationArea = {
  base: (theme: Theme) => css`
    padding: 0.25rem 0;
    font-size: 0.875rem;
    color: ${theme.system.textBase};
  `,

  disabled: (theme: Theme) => css`
    color: ${theme.disabled.inputLabelText};
    cursor: not-allowed;
  `,
};

export const errorText = (theme: Theme) => css`
  ${helpers.fontSizeNormalizer(14)};
  color: ${theme.danger.textBase};
  animation: ${animations.fadeIn} 0.25s ${animations.easeOutCirc};
  &:not(:last-of-type) {
    margin-bottom: 0.25rem;
  }
`;

export const errorMessageWrapper = css`
  display: flex;
  align-items: center;
`;

export const errorIcon = (theme: Theme) => css`
  color: ${theme.danger.textBase};
  margin-right: 0.5rem;
`;
