/* eslint-disable no-restricted-imports */
import { useEffect } from "react";
import type * as router from "react-router-dom";

import { useNavigate } from "../useNavigate/useNavigate";

/**
 * A Navigate component that works in Remix and react-router-dom
 * Remix doesn't expose a Navigate component, instead only giving access to useNavigate
 * For this to work in both react-router-dom and Remix, we instead re-implement Navigate to use useNavigate internally
 *
 * It can be used in interchangeably in a Remix or react-router-dom based SPA and work as expected
 * Means that devs don't need to worry about where an app is running and what API to use
 */
const Navigate = (props: router.NavigateProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(props.to, { replace: props.replace, state: props.state });
  }, []);

  return null;
};

export { Navigate };
