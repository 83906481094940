import { createInstance } from "i18next";

import { isDevelopment } from "@shared/utils/fullscriptEnv/fullscriptEnv";

import * as en from "./en";

const i18n = createInstance();
i18n.init({
  compatibilityJSON: "v3",
  debug: isDevelopment(),
  ns: Object.keys(en.US),
  defaultNS: "common",
  fallbackNS: "common",
  lng: "en",
  supportedLngs: ["en", "en-US"],
  fallbackLng: "en",
  resources: {
    en: en.US,
    "en-US": en.US,
  },
});

const l = en.US;

export { i18n, l };
