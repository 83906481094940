import { Skeleton, Spacer } from "@aviary";
import { SignInWrapper } from "@unauthenticated/shared/components/SignInWrapper/SignInWrapper";
import { SkeletonEditPasswordForm } from "@unauthenticated/signupSignin/components/EditPasswordForm/SkeletonEditPasswordForm";
import { SignInLayout } from "@unauthenticated/signupSignin/components/SignInLayout/SignInLayout";

const SkeletonEditPasswordPage = () => {
  return (
    <SignInLayout>
      <SignInWrapper>
        <Skeleton type="typography" typographySize="h1" margin="bottom" />
        <Skeleton type="typography" typographySize="body" margin="topAndBottom" />
        <Spacer height="one" />
        <SkeletonEditPasswordForm />
      </SignInWrapper>
    </SignInLayout>
  );
};

export { SkeletonEditPasswordPage };
