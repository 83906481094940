import { signIn as sharedSignIn } from "@unauthenticated/shared/locales/en/US/signIn";

export const signIn = {
  ...sharedSignIn,
  ForgotPassword: "Forgot password?",
  StaySignedIn: "Stay signed in",
  ContinueToFullscript: "Continue to Fullscript",
  Continue: "Continue",
  ResetPassword: "Reset your password",
  NotAuthorized: "You are not authorized to sign in with this email.",
  ContactAPrac: "Please contact your practitioner.",
  HappensToTheBest:
    "It happens to the best of us. We'll send you an email with instructions to reset your password.",
  EmailMeLink: "Email me a reset link",
  ReturnToSignIn: "Return to Sign In",
  SetYourPassword: "Set your new password",
  ItMustBeLong: "It must be at least 8 characters long.",
  PlaceholderPassword: "New password",
  PlaceHolderConfirmPassword: "Confirm new password",
  checkYourInbox: "Please check your inbox",
  EmailHasBeenSent: "An email has been sent to {{ email }}.",
  IfEmailExists:
    "If the account <1>{{ email }}</1> exists, you will receive an email with instructions on how to set a new password.",
  DidNotGetEmail: "Didn't get the email? Check your spam folder or <1>resend</1>.",
  EmailSentAgain: "Email succesfully sent again!",
  PasswordsDontMatch: "The passwords you entered do not match.",
  LinkIsBroken: "Hmm, that link appears to be broken.",
  TryAgainResetPassword: "To reset your password, please try again.",
  NoResetToken:
    "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",

  continueWithoutPassword: {
    title: "Get a new sign-in link",
    cta: "Send new link",
    description:
      "Please confirm your email address to access your practitioner's Fullscript dispensary.",
    confirmEmailAddress: "Please enter your email address below.",
    tryDifferentSignIn: "Try a different sign in option",
    error: {
      title: "Unable to send new link",
      description: "The link you are trying to access is invalid or expired.",
    },
    successPage: {
      title: "A new sign-in link is on the way",
      subTitle: "Please check your inbox to access Fullscript.",
      emailSent: "We sent an email to <emailText>{{ email }}</emailText>",
      DidNotGetEmail:
        "Didn't receive it? <resendLink>Resend the email</resendLink> or <signInLink>try a different sign-in option</signInLink>.",
      description:
        "If you have a Fullscript account, you will receive an <strong>Instant Link</strong> giving you access to your practitioner's Fullscript dispensary instantly. The link expires in 72 hours.",
      resentSuccess: "We've resent the email to you.",
    },
  },

  verifyEmail: {
    title: "Verify your email address",
    subTitle: "Please check your inbox to access Fullscript.",
    emailSent: "We sent an email to <emailText>{{ email }}</emailText>",
    DidNotGetEmail: "Didn't receive it? <resendLink>Resend the email</resendLink>",
    resentSuccess: "We've resent the email to you.",
  },
} as const;
