import { type SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Spacer } from "@aviary";
import { isEmailValid } from "@shared/components/EmailInput/EmailValidation/EmailValidation";
import { LoginOptions } from "@shared/components/LoginOptions/LoginOptions";
import { useHistory } from "@shared/context/HistoryContext";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import {
  useSearchParams,
  useNavigate,
  useLocation,
} from "@shared/react-router-dom/react-router-dom";
import { SwiftFillSteps } from "@shared/types/SwiftFillSteps";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";
import { RecaptchaErrorBox } from "@unauthenticated/shared/components/RecaptchaErrorBox/RecaptchaErrorBox";
import { SSOErrorBox } from "@unauthenticated/shared/components/SSOErrorBox/SSOErrorBox";
import { SignInEmailInput } from "@unauthenticated/shared/components/SignInForm/SignInEmailInput";
import { SignInWrapper } from "@unauthenticated/shared/components/SignInWrapper/SignInWrapper";
import { useGlobalConfig } from "@unauthenticated/shared/context/GlobalConfig/GlobalConfig";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import type { PatientAttributes } from "@unauthenticated/shared/data/types/PatientAttributes.d";
import { l } from "@unauthenticated/shared/locales/i18n";
import type { LocationType } from "@unauthenticated/signupSignin/data/types/PatientAuth";

import { usePatientNextLoginStep } from "./PatientNextLoginStep.mutation";

import * as styles from "./EmailCheckForm.styles";

interface Props {
  patientAttributes: PatientAttributes;
  additionalAttributes?: any;
  ssoErrors?: string;
  storeId?: string;
  isPreview: boolean;
}

const EmailCheckForm = ({
  patientAttributes,
  ssoErrors,
  storeId,
  isPreview,
  additionalAttributes,
}: Props) => {
  const SIGN_IN_PAGE = "rx_patient_sign_in_page";
  const PAGE_VERSION = "react_with_sso";
  const { emailRegex } = useGlobalConfig();
  const { t } = useTranslation();
  const { phone } = useBreakpoints();
  const { state, search }: LocationType = useLocation();
  const history = useHistory();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const intake = searchParams?.get("intake") === "true";
  const requestedPractitionerId = searchParams?.get("requestedPractitionerId");

  const [email, setEmail] = useState("");
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);

  const { treatmentPlanSlug, uuid, labRecommendationId, storeSlug } = patientAttributes;
  const identitySlug = uuid || treatmentPlanSlug;

  const resendParams = () => {
    const params = new URLSearchParams();
    if (additionalAttributes?.redirectPath) {
      params.append("redirect_path", additionalAttributes.redirectPath);
    }
    if (intake) {
      params.append("intake", intake.toString());
    }
    if (storeId) {
      params.append("store_id", storeId);
    }

    return params.toString();
  };
  const [patientNextLoginStep, { loading: isPatientNextLoginStepLoading }] =
    usePatientNextLoginStep({
      onCompleted: data => {
        const { nextStep } = data?.auth?.patientNextLoginStep || {};

        // Store email in the current history's state before redirecting to
        // preserve email when going back
        history.replace(history.location, { email });

        switch (nextStep) {
          case SwiftFillSteps.MAGIC_LINK:
            return navigate(
              {
                pathname: authRoutes.continue_without_password,
                search: resendParams(),
              },
              { state: { source: "RX_SIGN_IN", email, page: "success" } }
            );
          case SwiftFillSteps.CREATE_ACCOUNT:
            return navigate(
              {
                pathname: authRoutes.welcomeSignUp(storeSlug),
                search,
              },
              { state: { source: "RX_SIGN_IN", email } }
            );
          case SwiftFillSteps.PASSWORD:
            return navigate(
              { pathname: authRoutes.welcomePassword(storeSlug, identitySlug), search },
              { state: { source: "RX_SIGN_IN", email } }
            );
        }
      },
    });

  useEffect(() => {
    setEmail(state?.email || email);
  }, [state]);

  const onEmailChange = e => {
    setEmail(e.target.value);
    setIsEmailDirty(true);
  };

  const addPractitionerId = () => {
    if (!requestedPractitionerId) return {};

    return { requestedPractitionerId };
  };

  const onEmailSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    gRecaptchaExecute(captchaToken => {
      patientNextLoginStep({
        variables: {
          input: {
            captchaToken,
            attributes: {
              email,
              storeId,
              intake,
              redirectPath: additionalAttributes?.redirectPath,
              ...addPractitionerId(),
            },
          },
        },
      });
    }, setRecaptchaError);
  };

  const isButtonDisabled =
    !isEmailValid(email, emailRegex) || isPatientNextLoginStepLoading || isPreview;

  return (
    <SignInWrapper>
      <SSOErrorBox ssoErrors={ssoErrors} />
      {recaptchaError && <RecaptchaErrorBox />}
      <LoginOptions
        commonSSOProps={{
          signUpPage: SIGN_IN_PAGE,
          pageVersion: PAGE_VERSION,
          disabled: isPreview,
          treatmentPlanSlug,
          labRecommendationId,
          storeSlug,
          uuid,
          additionalAttributes,
        }}
      />
      <form onSubmit={onEmailSubmit} css={styles.form}>
        <SignInEmailInput
          value={email}
          isDirty={isEmailDirty}
          onChange={onEmailChange}
          required
          disabled={isPreview}
        />
        <Spacer height="oneHalf" />
        <Button onClick={onEmailSubmit} disabled={isButtonDisabled} isFullWidth={phone.lessThan}>
          {t(l.practitionerSignUp.ContinueWithEmail)}
        </Button>
      </form>
      <Spacer height="doubleHalf" />
    </SignInWrapper>
  );
};

export { EmailCheckForm };
