import { useTranslation } from "react-i18next";

import { SvgEmersonLogo } from "@shared/assets/logo";
import FullscriptLogoGreenLeafPigeonTextSVGComponent from "@shared/assets/logo/FullscriptLogoGreenLeafPigeonText";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { l } from "@shared/locales/i18n";
import { Link } from "@shared/react-router-dom/react-router-dom";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { e2eAttributes } from "@unauthenticated/shared/e2eAttributes";

import { Header } from "../Header/Header";

import * as styles from "./AuthHeader.styles";

interface Props {
  route?: string;
}

const AuthHeader = ({ route }: Props) => {
  const { t } = useTranslation();
  const { isEmerson } = useSharedGlobalConfig();

  const renderEmersonEcologicsLogo = () => (
    <Link to={authRoutes.emerson.base}>
      <SvgEmersonLogo
        role="img"
        data-testid="emerson-logo"
        data-e2e={e2eAttributes.login.emersonLogo}
        aria-label={t(l.companies.EmersonEcologics)}
        css={styles.emersonLogo}
      />
    </Link>
  );

  const renderPlatformLogo = () => {
    if (isEmerson) return renderEmersonEcologicsLogo();

    return (
      <FullscriptLogoGreenLeafPigeonTextSVGComponent
        css={styles.svgContainer}
        data-testid="fullscript-logo"
      />
    );
  };

  const renderLogo = () => {
    const content = (
      <div css={[styles.logoWrapper.base, isEmerson && styles.logoWrapper.fromCompanyByFullscript]}>
        {renderPlatformLogo()}
      </div>
    );

    if (route) {
      return (
        <Link aria-label="login" to={route}>
          {content}
        </Link>
      );
    }

    return content;
  };

  return <Header css={isEmerson && styles.header}>{renderLogo()}</Header>;
};

export { AuthHeader };
