/* eslint-disable no-restricted-imports, @fullscript/cross-reference */
import * as remix from "@remix-run/react";
import * as router from "react-router-dom";

/**
 * A Outlet component wrapping Outlet from Remix and react-router-dom
 * It can be used in interchangeably in a Remix or react-router-dom based SPA and work as expected
 * Means that devs don't need to worry about where an app is running and what API to use
 */
const Outlet = props => {
  let isRouter = true;

  try {
    router.useLocation();
  } catch (e) {
    isRouter = false;
  }

  if (isRouter) {
    return <router.Outlet {...props} />;
  }

  return <remix.Outlet {...props} />;
};

export { Outlet };
