import { css, type Theme } from "@emotion/react";

export const form = css`
  width: 100%;
  text-align: left;
`;

export const alignCenter = css`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const marginTop = css`
  margin-top: 1.5rem;
`;

export const icon = (theme: Theme) => css`
  margin-right: 0.5rem;
  color: ${theme.success.textBase};
`;
