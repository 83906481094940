import type { HTMLProps, KeyboardEvent, ReactNode } from "react";
import { useEffect, useRef } from "react";

import { useDropdownContext } from "../DropdownContext";

interface TriggerProps extends HTMLProps<HTMLDivElement> {
  removeRole?: boolean;
  children?: ReactNode;
}

const DropdownTrigger = ({ removeRole, children, ...rest }: TriggerProps) => {
  const {
    onTriggerClicked,
    isDropdownOpen,
    setMouseOverTrigger,
    setTriggerFocused,
    setTriggerElement,
    triggerElement,
  } = useDropdownContext();

  const triggerRef = useRef(null);

  useEffect(() => {
    if (isDropdownOpen && !triggerElement) {
      setTriggerElement(triggerRef.current);
    }
  }, [isDropdownOpen]);

  const onClickHandler = e => {
    onTriggerClicked(e);
    if (e) setTriggerElement(e.currentTarget);
  };

  const keyPressHandler = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      onClickHandler(event);
    }
  };

  const onMouseEnterHandler = e => {
    setMouseOverTrigger(true);
    if (e) setTriggerElement(e.currentTarget);
  };

  const onMouseLeaveHandler = () => {
    setMouseOverTrigger(false);
  };

  const onFocusHandler = () => {
    setTriggerFocused(true);
  };

  const onBlurHandler = () => {
    setTriggerFocused(false);
  };

  return (
    <div
      onClick={onClickHandler}
      onKeyPress={keyPressHandler}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      tabIndex={0}
      role={!removeRole ? "button" : "none"}
      ref={triggerRef}
      {...rest}
    >
      {children}
    </div>
  );
};

export { DropdownTrigger };
