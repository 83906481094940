import { css } from "@emotion/react";

export const fullHeight = css`
  height: 100%;
`;

export const svgContainer = css`
  width: 9rem;
  height: 2.15rem;
`;
