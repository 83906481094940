import { css, type Theme } from "@emotion/react";

import { dimensions } from "@styles";

export const separator = (theme: Theme) => css`
  height: 1px;
  margin: 0;
  background-color: ${theme.separator.base};
  width: 100%;
`;

export const withinBox = css`
  margin-left: -2rem;
  margin-right: -2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: calc(100% + 4rem);

  @media screen and (max-width: ${dimensions.phoneLargeMax}) {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;

export const labelSeparator = (theme: Theme) => css`
  display: flex;
  align-items: center;
  text-align: center;

  :not(:empty)::before {
    margin-right: 1.5rem;
  }
  :not(:empty)::after {
    margin-left: 1.5em;
  }

  ::before,
  ::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid ${theme.separator.base};
  }
`;

export const label = css`
  max-width: 50%;
  word-break: break-word;
`;
