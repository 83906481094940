import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

import type { AuthOfficeActivateAccountAttributes } from "@shared/types/graphqlGenerated";

const AUTH_OFFICE_ACTIVATE_ACCOUNT_MUTATION = gql`
  mutation OfficeActivateAccount_UnauthenticatedSignUpSignIn_Mutation(
    $input: AuthOfficeActivateAccountInput!
  ) {
    auth {
      officeActivateAccount(input: $input) {
        redirectPath
        errors {
          message
          fields {
            invitationToken
          }
        }
      }
    }
  }
`;

interface AuthOfficeActivateAccountFieldError {
  invitationToken: string[];
}

interface AuthOfficeActivateAccountError {
  fields: AuthOfficeActivateAccountFieldError;
  message: string;
}

interface OfficeActivateAccountMutationData {
  auth: {
    officeActivateAccount: {
      redirectPath: string;
      errors: AuthOfficeActivateAccountError;
    };
  };
}

interface Variables {
  input: {
    captchaToken: string;
    attributes: AuthOfficeActivateAccountAttributes;
  };
}

const useOfficeActivateAccountMutation = (
  options?: MutationHookOptions<OfficeActivateAccountMutationData, Variables>
) =>
  useMutation<OfficeActivateAccountMutationData, Variables>(
    AUTH_OFFICE_ACTIVATE_ACCOUNT_MUTATION,
    options
  );

export type { OfficeActivateAccountMutationData };
export { useOfficeActivateAccountMutation, AUTH_OFFICE_ACTIVATE_ACCOUNT_MUTATION };
