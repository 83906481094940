import type { ReactNode } from "react";

import { container } from "./SignUpContainer.styles";

interface Props {
  children: ReactNode;
  isNoPaddingTop?: boolean;
}

const SignUpContainer = ({ children }: Props) => {
  return <div css={[container]}>{children}</div>;
};

export { SignUpContainer };
