import { faStethoscope, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { Trans } from "react-i18next";

import { Typography } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { FontAwesomeIcon } from "@shared/react-fontawesome/react-fontawesome";
import { useLocation } from "@shared/react-router-dom/react-router-dom";
import { BoxLink } from "@unauthenticated/shared/components/BoxLink/BoxLink";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./ImAPractitionerButton.styles";

const ImAPractitionerButton = () => {
  const { phone } = useBreakpoints();
  const { search } = useLocation();

  const renderMobileText = () => {
    if (phone.greaterThan) {
      return (
        <Trans i18nKey={l.practitionerSignUp.ImAPractitioner}>
          I'm a practitioner - Take me to <strong>practitioner</strong> sign-up
        </Trans>
      );
    }

    return (
      <Trans i18nKey={l.practitionerSignUp.ImAPractitionerMobile}>
        Take me to <strong>practitioner</strong> sign-up
      </Trans>
    );
  };

  return (
    <BoxLink
      to={{
        pathname: authRoutes.practitioner_sign_up,
        search: `${search ? search + "&" : "?"}step=1`,
      }}
      css={styles.boxLink}
    >
      {phone.greaterThan && (
        <FontAwesomeIcon icon={faStethoscope} size="lg" css={styles.stethoscope} />
      )}
      <Typography isMarginless>{renderMobileText()}</Typography>
      <FontAwesomeIcon
        icon={faArrowRight}
        size="lg"
        css={styles.arrowIcon}
        className="icon-right"
      />
    </BoxLink>
  );
};

export { ImAPractitionerButton };
