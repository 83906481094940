import { css } from "@emotion/react";
import type { Theme } from "@emotion/react";

import * as dimensions from "@styles/emotion-styles/dimensions";

export const footer = (theme: Theme) => css`
  width: 100%;
  background: ${theme.surface.level1};
  flex-shrink: 0;
  margin-top: 1rem;
`;

export const topBorder = (theme: Theme) => css`
  border-top: 1px solid ${theme.separator.base};
`;

export const mobileWrapper = css`
  display: flex;
  flex-direction: column;
`;

export const wrapper = css`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  @media (max-width: ${dimensions.tabletMax}) {
    flex-direction: column;
    padding: 0;
  }
`;
