import { css, type Theme } from "@emotion/react";

import { boxShadowMaker } from "@styles/emotion-styles/helpers/boxShadowMaker";

/**
 * @deprecated
 * Use Aviary input components such as FloatingLabelInput or TextField instead
 */

export const inputTextBoxMaker = (theme: Theme) => css`
  ${boxShadowMaker};
  height: 2.25rem;
  width: 100%;
  padding-left: 0.625rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.625rem;
  color: ${theme.input.textBase};
  caret-color: ${theme.info.borderBase};

  ::placeholder {
    color: ${theme.input.textPlaceholder};
  }

  &[disabled],
  fieldset[disabled] & {
    background-color: ${theme.disabled.background};
    color: ${theme.disabled.inputLabelText};
    border: 1px solid ${theme.disabled.border};
    cursor: not-allowed;
  }

  /* States */
  &:focus,
  &:focus-within,
  &:active {
    outline: none;
    border-color: ${theme.input.borderActive};
  }
`;
