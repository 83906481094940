import { css } from "@emotion/react";

export const imgStyles = css`
  border-radius: 1rem;
  overflow: hidden;
`;

export const textStyling = css`
  color: white;
  max-width: 22rem;
  margin: 0 auto 2.5rem !important;
`;
