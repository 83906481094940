import { css, type Theme } from "@emotion/react";

export const fullHeight = css`
  height: 100%;
`;

export const columnsContainer = css`
  ${fullHeight}
  margin: 0;
`;

export const leftColumn = (theme: Theme) => css`
  background: ${theme.primary.backgroundBox};
  border-right: 1px solid ${theme.system.borderBase};
  padding: 1rem 7rem;
  text-align: center;
`;

export const rightColumn = css`
  padding: 1rem;
  margin: auto;
`;

export const layoutContainer = css`
  flex: 1 0 auto;
`;
