import { useEffect } from "react";

import { usePatientSignUpLoggerMutation } from "./PatientSignUpLogger.mutation";

type Input = {
  eventAggregateId: string;
  signUpPage: string;
  pageVersion: string;
  fields?: string[];
};

const cache = {};

const useLogPatientSignUp = ({ eventAggregateId, signUpPage, pageVersion, fields }: Input) => {
  const [log] = usePatientSignUpLoggerMutation();

  const logFieldExpected = field =>
    log({
      variables: {
        input: {
          attributes: {
            eventAggregateId,
            eventType: "field_expected",
            pageVersion,
            signUpPage,
            field,
          },
        },
      },
    });

  const logFieldsExpected = () => {
    if (fields) {
      fields.forEach(logFieldExpected);
    } else {
      ["password"].forEach(logFieldExpected);
    }
  };

  useEffect(() => {
    if (cache[signUpPage]) return;
    cache[signUpPage] = true;
    log({
      variables: {
        input: {
          attributes: {
            eventAggregateId,
            eventType: "session_begin",
            pageVersion,
            signUpPage,
          },
        },
      },
    }).then(() => {
      logFieldsExpected();
    });
  }, []);

  const logField = (field: string) => {
    if (cache[field]) return;
    cache[field] = true;
    log({
      variables: {
        input: {
          attributes: {
            field,
            eventAggregateId,
            eventType: "field_updated",
            pageVersion,
            signUpPage,
          },
        },
      },
    });
  };

  return { logField };
};

export { useLogPatientSignUp };
