export const canUseWebp = () => {
  if (typeof window === "undefined") return false;
  let supportsWebP = false;
  const elem = document.createElement("canvas");

  try {
    supportsWebP =
      elem?.getContext?.("2d") && elem?.toDataURL("image/webp")?.startsWith("data:image/webp");
  } catch {
    // can't access dead object: #25359
  }

  return supportsWebP;
};
