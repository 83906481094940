import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const AnalyticsUpdateProfile_Shared_Mutation = gql`
  mutation AnalyticsUpdateProfile_Shared_Mutation($input: AnalyticsUpdateProfileInput!) {
    analyticsUpdateProfile(input: $input) {
      errors {
        message
      }
    }
  }
`;

interface AnalyticsUpdateProfileError {
  message: string;
}

interface AnalyticsUpdateProfileData {
  analyticsUpdateProfile: {
    errors: AnalyticsUpdateProfileError;
  };
}

interface Variables {
  input: {
    payload: {
      utm_source?: string;
      utm_campaign?: string;
      utm_medium?: string;
    };
  };
}

const useAnalyticsUpdateProfileMutation = (
  options?: MutationHookOptions<AnalyticsUpdateProfileData, Variables>
) =>
  useMutation<AnalyticsUpdateProfileData, Variables>(
    AnalyticsUpdateProfile_Shared_Mutation,
    options
  );

export type { AnalyticsUpdateProfileData };

/* eslint-disable @fullscript/gql-no-manual-hook-declaration */
export { useAnalyticsUpdateProfileMutation, AnalyticsUpdateProfile_Shared_Mutation };
