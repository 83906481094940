import { css } from "@emotion/react";

export const centered = css`
  text-align: center;
`;

export const text = css`
  ${centered}
  padding: 1rem 0;
`;
