import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const OFFICE_SIGN_UP_MUTATION = gql`
  mutation OfficeSignUp_UnauthenticatedSignUpSignIn_Mutation($input: AuthOfficeSignUpInput!) {
    auth {
      officeSignUp(input: $input) {
        redirectPath
        errors {
          message
          fields {
            password
          }
        }
      }
    }
  }
`;

interface AuthOfficeSignUpFieldError {
  password: string[];
}

interface AuthOfficeSignUpError {
  fields: AuthOfficeSignUpFieldError;
  message: string;
}

interface OfficeSignUpMutationData {
  auth: {
    officeSignUp: {
      redirectPath: string;
      errors: AuthOfficeSignUpError;
    };
  };
}

interface OfficeSignUpAttributes {
  password: string;
  invitationToken: string;
  pageVersion: string;
  signUpPage: string;
}

interface Variables {
  input: {
    captchaToken: string;
    attributes: OfficeSignUpAttributes;
  };
}

const useOfficeSignUpMutation = (
  options?: MutationHookOptions<OfficeSignUpMutationData, Variables>
) => useMutation<OfficeSignUpMutationData, Variables>(OFFICE_SIGN_UP_MUTATION, options);

export type { OfficeSignUpMutationData };
export { useOfficeSignUpMutation, OFFICE_SIGN_UP_MUTATION };
