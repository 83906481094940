import { css } from "@emotion/react";

import * as dimensions from "@styles/emotion-styles/dimensions";

export const form = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const marginBottom = css`
  margin-bottom: 1rem;
`;

export const serviceTerms = css`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: ${dimensions.phoneMax}) {
    white-space: nowrap;
  }
`;
