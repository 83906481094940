import { css } from "@emotion/react";

export const freeText = css`
  text-transform: uppercase;
`;

export const textAlign = css`
  text-align: center;
  width: 100%;
`;

export const subTitle = css`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
`;
