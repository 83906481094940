import { css } from "@emotion/react";

// export const calculateRem = (size: number) => {
//   return "blah";
// };

// TODO: Once everything (practitioner, admin, patient) is on bulma, we need to use the rem size instead
export const fontSizeNormalizer = (size: number) => {
  return css`
    font-size: ${size}px;
  `;
};
