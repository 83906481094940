import { css } from "@emotion/react";
import { typography } from "aviary-tokens";

import * as dimensions from "@styles/emotion-styles/dimensions";

export const form = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const marginBottom = css`
  margin-bottom: 1rem;
`;

export const serviceTerms = css`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: ${dimensions.phoneMax}) {
    white-space: nowrap;
  }
`;

export const Row = css`
  display: flex;
  width: 100%;
  ${marginBottom}

  @media (max-width: ${dimensions.phoneMax}) {
    flex-direction: column;
  }
`;

export const margin = css`
  margin-right: 1rem;

  @media (max-width: ${dimensions.phoneMax}) {
    margin-right: 0;
    ${marginBottom};
  }
`;

export const email = css`
  margin-bottom: 1rem;
`;

export const patientEmail = css`
  font-weight: ${typography.weightSemiBold};
`;
