import { useState, type SyntheticEvent } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Illustration, Link, Message, Spacer, Typography } from "@aviary";
import { BackButton } from "@aviary/layouts";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";
import type { UserSendMagicLinkMutationData } from "@unauthenticated/shared/data/mutations/UserSendMagicLink.mutation";
import { useUserSendMagicLinkMutation } from "@unauthenticated/shared/data/mutations/UserSendMagicLink.mutation";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./VerifyEmailContent.styles";

interface Props {
  email: string;
}

const VerifyEmailContent = ({ email }: Props) => {
  const { t } = useTranslation();
  const [showResendSuccess, setShowResendSuccess] = useState<boolean>(false);

  const onCompleted = (data: UserSendMagicLinkMutationData) => {
    const { message: errorMessage } = data?.auth?.userSendMagicLink?.errors ?? {};

    if (!errorMessage) {
      setShowResendSuccess(true);
    }
  };

  const [sendMagicLink] = useUserSendMagicLinkMutation();
  const handleSendLink = (e: SyntheticEvent) => {
    e.preventDefault();

    gRecaptchaExecute(gToken => {
      sendMagicLink({
        variables: {
          input: {
            captchaToken: gToken,
            email,
          },
        },
        onCompleted,
      });
    });
  };

  const renderEmailSentLine = () => {
    if (!email) {
      return null;
    }

    return (
      <>
        <Typography type="body">
          <Trans
            i18nKey={l.signIn.verifyEmail.emailSent}
            values={{ email }}
            components={{
              emailText: <strong />,
            }}
          />
        </Typography>
        <Spacer height="one" />
      </>
    );
  };

  return (
    <>
      {showResendSuccess && (
        <Message isColor="success">{t(l.signIn.verifyEmail.resentSuccess)}</Message>
      )}

      <div css={styles.textWrapper}>
        <Typography type="h2">{t(l.signIn.verifyEmail.title)}</Typography>
        <Typography type="h3">{t(l.signIn.verifyEmail.subTitle)}</Typography>
        <Spacer height="oneHalf" />
        <Illustration illustration="envelope" heightAndWidth={12} />
        <Spacer height="oneHalf" />
        {renderEmailSentLine()}
        <Typography>
          <Trans
            i18nKey={l.signIn.verifyEmail.DidNotGetEmail}
            components={{
              resendLink: <Link onClick={handleSendLink} />,
            }}
          />
        </Typography>
      </div>

      <BackButton text={t(l.auth.back)} isUnderLined={false} />
    </>
  );
};

export { VerifyEmailContent };
