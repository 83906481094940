import type { IconDefinition } from "@fortawesome/fontawesome-common-types";

import { Arrange, SelectableTile, CircularIcon, Column, Typography } from "@aviary";

import * as styles from "./SignUpTriage.styles";

interface Props {
  name: string;
  icon: IconDefinition;
  isSelected: boolean;
  onSelected: () => void;
  isFullHeight: boolean;
}

const RoleTile = ({ name, icon, isSelected, onSelected, isFullHeight }: Props) => {
  return (
    <Column columnWidth={8}>
      <SelectableTile
        isContentHeight
        contentWrapperStyles={styles.tileMobile}
        checked={isSelected}
        isRadio
        aria-label={name}
        onChange={onSelected}
        isFullHeight={isFullHeight}
      >
        <Arrange isVertical alignment="center" spacing="large" css={styles.iconSpacing}>
          <CircularIcon size="large" icon={icon} />
          <Typography type="h4" css={styles.textPadding}>
            {name}
          </Typography>
        </Arrange>
      </SelectableTile>
    </Column>
  );
};

export { RoleTile };
