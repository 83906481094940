import { lazy, Suspense } from "react";

import { SkeletonSignInPage } from "./SkeletonSignInPage";

const SignInPage = lazy(() => import("./SignInPageDefault"));

interface Props {
  additionalAttributes?: any;
  ssoErrors?: string;
  authErrors?: string;
}

const LazySignInPage = ({ additionalAttributes, ssoErrors, authErrors }: Props) => {
  const formVersion = "MainSigninPage";

  return (
    <Suspense fallback={<SkeletonSignInPage />}>
      <SignInPage
        ssoErrors={ssoErrors}
        authErrors={authErrors}
        additionalAttributes={{ formVersion, ...additionalAttributes }}
      />
    </Suspense>
  );
};

export { LazySignInPage };
