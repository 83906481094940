import { css, type Theme } from "@emotion/react";
import type { ColorProfileTheme } from "aviary-tokens/dist/types";

export const box = (theme: Theme) => css`
  color: ${theme.text.body};

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const contentWrapper = css`
  display: flex;
`;
export const content = css`
  flex-grow: 1;
`;

export const icon = css`
  margin-right: 0.5rem;
`;

export const themedColorStyles = (theme: ColorProfileTheme) => css`
  border-color: ${theme.borderBase};
  background-color: ${theme.backgroundBox};
`;

export const themedColorIcon = (theme: ColorProfileTheme) => css`
  path {
    fill: ${theme.textBase};
  }
`;

export const systemColor = (theme: Theme) => css`
  border-color: ${theme.system.borderActive};
  background-color: ${theme.system.backgroundMuted};
`;

export const systemIcon = (theme: Theme) => css`
  path {
    fill: ${theme.text.body};
  }
`;

export const title = css`
  && {
    margin-bottom: 0.5rem;
  }
`;
