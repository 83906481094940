import { Suspense, lazy } from "react";

import { SkeletonForgotPasswordPage } from "./SkeletonForgotPasswordPage";
const ForgotPasswordPage = lazy(() => import("./ForgotPasswordPageDefault"));

const LazyForgotPasswordPage = () => {
  return (
    <Suspense fallback={<SkeletonForgotPasswordPage />}>
      <ForgotPasswordPage />
    </Suspense>
  );
};

export { LazyForgotPasswordPage };
