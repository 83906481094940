import { useTranslation } from "react-i18next";

import { Carousel, CarouselItem, CarouselItems, CarouselRadio } from "@aviary";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./StoreSignupInCarousel.styles";

const StoreSignupInCarousel = () => {
  const { t } = useTranslation();

  return (
    <Carousel autoPlay={{ speedInMs: 6000 }} loop css={styles.carouselContainer}>
      <CarouselItems>
        <CarouselItem>
          {t(l.patientRxAuth.storeLandingPages.leftColumn.carousel.slide1)}
        </CarouselItem>
        <CarouselItem>
          {t(l.patientRxAuth.storeLandingPages.leftColumn.carousel.slide2)}
        </CarouselItem>
        <CarouselItem>
          {t(l.patientRxAuth.storeLandingPages.leftColumn.carousel.slide3)}
        </CarouselItem>
      </CarouselItems>
      <CarouselRadio />
    </Carousel>
  );
};

export { StoreSignupInCarousel };
