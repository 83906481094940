import { Trans } from "react-i18next";

import { Link, Typography } from "@aviary";
import { l } from "@shared/locales/i18n";

import * as styles from "./FooterPrivacy.styles";

const FooterPrivacy = ({ ...rest }) => {
  const GOOGLE_TERMS_SERVICE_URL = "https://policies.google.com/terms";
  const GOOGLE_PRIVACY_POLICY_URL = "https://policies.google.com/privacy";

  return (
    <Typography isFullWidth type="footnote" {...rest}>
      <Trans i18nKey={l.sharedCommon.footerTerms}>
        This page is protected by reCAPTCHA, and subject to the &nbsp;
        <Link css={styles.link} href={GOOGLE_PRIVACY_POLICY_URL} noExternalIcon>
          Google Privacy Policy
        </Link>
        and &nbsp;
        <Link css={styles.link} href={GOOGLE_TERMS_SERVICE_URL} noExternalIcon>
          Google Terms of Service
        </Link>
      </Trans>
    </Typography>
  );
};

export { FooterPrivacy };
