import { css, type Theme } from "@emotion/react";

import { Size } from "@shared/types/sizes";
import { animations, dimensions } from "@styles";
import { inputTextBoxMaker } from "@styles/emotion-styles/helpers";

export const searchBar = {
  base: (theme: Theme) => css`
    ${inputTextBoxMaker(theme)};
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 3rem;

    svg path {
      ${animations.transition()};
      fill: ${theme.input.textBase};
    }

    &:hover {
      border-color: ${theme.input.borderHover};
    }

    &:active,
    &:focus-within,
    &:focus {
      border-color: ${theme.input.borderActive};

      svg path {
        fill: ${theme.input.borderActive};
      }
    }

    @media only screen and (max-width: ${dimensions.phoneMax}) {
      padding-right: 0.5rem;
    }
  `,
  [Size.MEDIUM]: css`
    height: 3.5rem;
  `,
  [Size.SMALL]: css`
    height: 2.5rem;
  `,
  disabled: (theme: Theme) => css`
    background-color: ${theme.disabled.background};
    color: ${theme.disabled.inputLabelText};
    border: 1px solid ${theme.disabled.border};
    user-select: none;
    cursor: not-allowed;

    &:hover,
    &:active,
    &:focus-within,
    &:focus {
      background-color: ${theme.disabled.background};
      color: ${theme.disabled.inputLabelText};
      border: 1px solid ${theme.disabled.border};
      box-shadow: none;
    }
  `,
};

export const searchIcon = css`
  margin: 0 0.25rem;
`;

export const inputStyles = (theme: Theme) => css`
  width: 100%;
  max-width: 100%;
  border: none;
  padding: 0.5rem;
  font-size: ${theme.aviaryTypography.body.fontSize};
  color: ${theme.input.textBase};

  ::placeholder {
    color: ${theme.input.textPlaceholder};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &[disabled] {
    user-select: none;
    cursor: not-allowed;
    background-color: ${theme.disabled.background};
  }
`;
