import { Skeleton, Spacer } from "@aviary";

import { noStyleButton } from "./ReturnToSignInButton.styles";

const SkeletonReturnToSignInButton = () => {
  return (
    <div css={noStyleButton}>
      <Skeleton type="button" width={1} height={1} />
      <Spacer width="one" />
      <Skeleton type="button" buttonSize="small" width={8} height={2} />
    </div>
  );
};

export { SkeletonReturnToSignInButton };
