import { useTranslation } from "react-i18next";

import { Stepper } from "@aviary";
import { l } from "@unauthenticated/shared/locales/i18n";

type Props = {
  size?: "small" | "large";
  currentStep: number;
  hidden?: boolean;
};

import * as styles from "./PractitionerSignupStepper.styles";

const PractitionerSignupStepper = ({ size = "small", currentStep, hidden = false }: Props) => {
  const { t } = useTranslation();

  return (
    <div css={styles.stepper} hidden={hidden}>
      <Stepper
        size={size}
        isDashed
        isIncompleteFinalStep
        currentStep={currentStep}
        steps={[
          { label: t(l.common.practitionerSignupStepper.step1) },
          { label: t(l.common.practitionerSignupStepper.step2) },
          { label: t(l.common.practitionerSignupStepper.step3) },
        ]}
      />
    </div>
  );
};

export default PractitionerSignupStepper;
