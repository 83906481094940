import { AuthFooter } from "@shared/components/AuthFooter/AuthFooter";
import { AuthHeader } from "@shared/components/AuthHeader/AuthHeader";
import { AuthHeaderWrapper } from "@shared/components/AuthHeaderWrapper/AuthHeaderWrapper";
import { useLocation } from "@shared/react-router-dom/react-router-dom";
import type { StorePlatform } from "@shared/types/StorePlatform";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { LinkExpiredNotificationPage } from "@unauthenticated/signupSignin/components/LinkExpiredNotificationPage/LinkExpiredNotificationPage";
import { LinkNotificationPage } from "@unauthenticated/signupSignin/components/LinkNotificationPage/LinkNotificationPage";
import { Page } from "@unauthenticated/signupSignin/components/Page/Page";
import { PageContent } from "@unauthenticated/signupSignin/components/PageContent/PageContent";

import { VerifyEmailContent } from "./VerifyEmailContent/VerifyEmailContent";

import * as styles from "./VerifyEmailPage.styles";

interface HistoryState {
  email?: string;
}

const VerifyEmailPage = () => {
  const { state }: { state: HistoryState } = useLocation();
  const { search } = useLocation();

  const linkEmail = new URLSearchParams(search).get("email");
  const linkHasError = new URLSearchParams(search).get("has_error") === "true";
  const linkStorePlatform = new URLSearchParams(search).get(
    "enable_shared_account"
  ) as StorePlatform;

  if (linkEmail && linkStorePlatform) {
    if (linkHasError) {
      return <LinkExpiredNotificationPage email={linkEmail} storePlatform={linkStorePlatform} />;
    }

    return <LinkNotificationPage email={linkEmail} storePlatform={linkStorePlatform} />;
  }

  return (
    <Page>
      <PageContent>
        <AuthHeaderWrapper>
          <AuthHeader route={authRoutes.login} />
        </AuthHeaderWrapper>
        <div css={styles.contentLayout}>
          <VerifyEmailContent email={state?.email} />
        </div>
      </PageContent>
      <AuthFooter />
    </Page>
  );
};

export { VerifyEmailPage };
