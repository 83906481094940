import { common as sharedCommon } from "@unauthenticated/shared/locales/en/US/common";
/* eslint-disable @typescript-eslint/naming-convention */
export const common = {
  ...sharedCommon,
  Patient: "Patient",
  Practitioner: "Practitioner",
  Email: "Email",
  PasswordUpdatedSuccess: "Password has been successfully updated!",
  LoggingYouIn: "Logging you in",
  PasswordsMatch: "Passwords match",
  PasswordPrompt: "Input your password to continue",
  GoToUS: "Go to Fullscript US",
  GoToCA: "Go to Fullscript Canada",
  USLogin: "United States Login Page",
  CALogin: "Canada Login Page",
  USFlag: "US flag icon",
  CAFlag: "Canadian maple leaf icon",
  GoogleLogo: "Google logo",
} as const;
