import { css, type Theme } from "@emotion/react";

import * as dimensions from "@styles/emotion-styles/dimensions";

export const serviceTerms = css`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: ${dimensions.phoneMax}) {
    white-space: nowrap;
  }
`;

export const linkStyles = (theme: Theme) => css`
  white-space: nowrap;
  color: ${theme.success.textBase};
`;
