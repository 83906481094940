export const intake = {
  signInDescription: "Sign in to Fullscript to access your supplement intake form.",
  signUpDescription:
    "First, create an account with Fullscript to access your supplement intake form.",
  welcome: "Welcome to Fullscript",
  welcomeDescription:
    "Save time and take the stress out of your appointment by completing your supplement intake form.",
  haveiPhone: "If you have an iPhone",
  stepOne: "1. Sign up/in",
  stepOneDescription:
    "Create a free account to get started with Fullscript or sign in through the link below",
  toFullscriptCTA: "Continue to Fullscript",
  stepTwo: "2. Download the iOS app",
  stepTwoDescription:
    "After creating your account, download the Fullscript app. Once signed in, you'll be prompted to complete your supplement intake.",
  haveNoiPhone: "If you don't have an iPhone",
  haveNoiPhoneDescription:
    "Fullscript's <1>intake process</1> is currently only for iOS. Please connect with your practitioner for alternative intake options.",
};
