import { useEffect, useRef, useState } from "react";

const useFloatingLabelFocus = onBlur => {
  const [isFocused, setIsFocused] = useState(false);
  const inputElement = useRef(null);

  const handleBlur = e => {
    onBlur?.(e);
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const onAutoFillStart = () => {
    setIsFocused(true);
  };

  const onAutoFillCancel = (e: AnimationEvent) => {
    waitForAutoFill(e);
    setIsFocused(false);
  };

  const waitForAutoFill = (e: AnimationEvent) => {
    const interval = setInterval(() => {
      const target = e?.target as HTMLInputElement;
      if (target?.value) {
        clearInterval(interval);
      }
    }, 100);
  };
  // Detect Autofill
  const onAnimationStart = (e: AnimationEvent) => {
    switch (e.animationName) {
      case "onAutoFillStart":
        return onAutoFillStart();
      case "onAutoFillCancel":
        return onAutoFillCancel(e);
    }
  };

  const onAnimationEnd = (e: AnimationEvent) => {
    if (e.animationName === "onAutoFillStart") {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined" && inputElement.current) {
      inputElement.current.addEventListener("animationstart", onAnimationStart, false);
      inputElement.current.addEventListener("animationend", onAnimationEnd, false);
    }
  }, []);

  return { isFocused, handleFocus, handleBlur, inputElement };
};

export { useFloatingLabelFocus };
