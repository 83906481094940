import { Typography } from "@aviary";

import { subtitle as subtitleStyles } from "./SignUpSubtitle.styles";

interface Props {
  subtitle: string;
}

const SignUpSubtitle = ({ subtitle }: Props) => {
  return (
    <Typography type="body" css={subtitleStyles}>
      {subtitle}
    </Typography>
  );
};

export { SignUpSubtitle };
