import { css } from "@emotion/react";

export const form = css`
  width: 100%;
`;

export const textLeft = css`
  text-align: left;
`;

export const subtitle = css`
  text-align: center;
  margin-bottom: 2rem;
`;

export const row = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const checkboxWrapper = css`
  ${textLeft}
  margin-bottom: 0.5rem;
`;
