export const linkNotification = {
  title: "A sign-in link is on the way",
  subTitle:
    "Use one account to access healthcare's best supplements on Fullscript and Emerson Ecologics.",
  existingEmail: {
    ee: "You have an existing Fullscript account with the email <strong>{{email}}.</strong>",
    fs: "You have an existing Emerson Ecologics account with the email <strong>{{email}}.</strong>",
  },
  emailSent: {
    ee: "An email has been sent to help finish setting up Emerson Ecologics.",
    fs: "An email has been sent to help finish setting up Fullscript.",
  },
  linkSent: "New sign-in link sent",
  linkError: "Error sending sign-in link",
  newSignInLink: "A new sign-in link is on the way",
  pleaseCheck: {
    ee: "Please check your inbox to access Emerson Ecologics.",
    fs: "Please check your inbox to access Fullscript.",
  },
  emailedTo: "An email has been sent to <bold>{{email}}</bold> to confirm your account",
  didntGetEmail: "Didn't get the email? Check your spam folder or <1>resend</1>.",
  resend: "resend",
  returnToSignUp: "Return to sign up",
  newResendLink: "New sign-in link sent",
  errorResendLink: "Error resending sign-in link",
};
