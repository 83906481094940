import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Avatar, Button, Spacer, Tooltip, Typography } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useCallistoPracOnboardingExperiment } from "@shared/hooks/useCallistoPracOnboardingExperiment/useCallistoPracOnboardingExperiment";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { FontAwesomeIcon } from "@shared/react-fontawesome/react-fontawesome";
import type { PractitionerAdditionalAttributesType } from "@shared/types/PractitionerAdditionalAttributesType";
import { StorePlatform } from "@shared/types/StorePlatform";
import { DesignationSearch } from "@unauthenticated/shared/components/DesignationSearch/DesignationSearch";
import { DispensaryNameInput } from "@unauthenticated/shared/components/DispensaryNameInput/DispensaryNameInput";
import { FirstNameInput } from "@unauthenticated/shared/components/FirstNameInput/FirstNameInput";
import { FormWrapper } from "@unauthenticated/shared/components/FormWrapper/FormWrapper";
import { LastNameInput } from "@unauthenticated/shared/components/LastNameInput/LastNameInput";
import { IsNameValid } from "@unauthenticated/shared/components/NameValidation/NameValidation";
import { SSOErrorBox } from "@unauthenticated/shared/components/SSOErrorBox/SSOErrorBox";
import { TermsOfServiceCheckbox } from "@unauthenticated/shared/components/TermsOfServiceCheckbox/TermsOfServiceCheckbox";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "../SimplifySignUp.styles";
import type { SimplifySignUpProps } from "../types";

import { isFormValid } from "./helpers";

interface Props extends SimplifySignUpProps {
  onClick: (e: FormEvent<HTMLFormElement>) => void;
  additionalAttributes?: PractitionerAdditionalAttributesType;
}

const PageOne = ({
  errors,
  onClick,
  formData,
  setFormData,
  logField,
  additionalAttributes,
  ssoErrors,
}: Props) => {
  const { isEmerson } = useSharedGlobalConfig();
  const { t } = useTranslation();
  const { phone, phoneLarge } = useBreakpoints();
  const [inputDirty, setInputDirty] = useState({
    firstName: false,
    lastName: false,
    dispensaryName: false,
  });

  const isPeerReferral = !!additionalAttributes?.peerReferralDisplayAttributes;
  const isPracOnboardingEnabled = useCallistoPracOnboardingExperiment() && !isPeerReferral;
  const headerText = isEmerson
    ? t(l.practitionerSignUp.Emerson.CreateAccountHeader)
    : t(l.practitionerSignUp.CreateAccountHeader);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));

    if (errors?.[name]) {
      errors[name] = [];
    }
  };

  const getDefaultDispensaryName = () => {
    const { firstName, lastName } = formData;

    if (!firstName || !lastName) return "";

    const lastChar = lastName[lastName.length - 1];
    const s = lastChar === "s" || lastChar === "S" ? "" : "s";
    const fullName = `${firstName} ${lastName}'${s}`;

    if (isEmerson) {
      return t(l.common.DispensaryNameDefaultWithPlatform, {
        fullName,
        storePlatform: StorePlatform.EMERSON,
      });
    }

    return t(l.common.DispensaryDisplayName, { fullName });
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    setInputDirty(prevState => ({ ...prevState, [name]: true }));
    logFieldOnBlur(name);
  };

  const handleDispensaryNameBlur = () => {
    if (formData.dispensaryName !== "") logField("dispensaryName");
  };

  const handleSetDesignationValue = pracType => {
    setFormData(prevState => ({ ...prevState, practitionerType: pracType }));
    handleLogPracTypeField(pracType);
  };

  const handleLogPracTypeField = (pracType = null) => {
    if (pracType !== null) logField("practitionerType");
  };

  const logFieldOnBlur = (inputName: string) => {
    switch (inputName) {
      case "firstName":
        if (IsNameValid(formData.firstName)) logField(inputName);
        break;
      case "lastName":
        if (IsNameValid(formData.lastName)) logField(inputName);
        break;
    }
  };

  const updateTimestamp = tosTimestamp => {
    setFormData(prevState => ({ ...prevState, tosTimestamp }));
  };

  const renderHeader = () => {
    if (isPeerReferral) {
      const { referrerFirstName, referrerAvatar } =
        additionalAttributes?.peerReferralDisplayAttributes;

      return (
        <div css={styles.peerReferralTitle}>
          {!!referrerAvatar && (
            <Avatar
              size="small"
              image={referrerAvatar}
              css={styles.userAvatar}
              label={`${referrerFirstName} avatar`}
            />
          )}
          <Typography type="h3">
            {t(l.practitionerSignUp.peerReferral.signUpTitle, { referrerFirstName })}
          </Typography>
        </div>
      );
    }

    return isPracOnboardingEnabled ? (
      <Typography type="h3">{t(l.practitionerSignUp.PracOnboarding.headerText)}</Typography>
    ) : (
      <Typography type="h2">{headerText}</Typography>
    );
  };

  const renderNextButton = () => {
    const isButtonDisabled = !isFormValid(formData);

    const nextButton = (
      <Button
        data-e2e="next_button"
        type="submit"
        disabled={isButtonDisabled}
        isFullWidth
        size={isPracOnboardingEnabled ? "large" : "medium"}
      >
        {isPracOnboardingEnabled
          ? t(l.practitionerSignUp.PracOnboarding.nextButtonText)
          : t(l.practitionerSignUp.NextSelectSignUpMethod)}
      </Button>
    );

    if (isButtonDisabled) {
      return (
        <Tooltip
          css={styles.nextButtonTooltip}
          tooltipContent={t(l.practitionerSignUp.disabledNextButtonTooltip)}
        >
          {nextButton}
        </Tooltip>
      );
    }

    return nextButton;
  };

  const renderFirstLastNameFields = () => {
    const nameTooltip = (
      <Tooltip
        tooltipContent={t(l.practitionerSignUp.PracNameTooltip)}
        data-testid="prac-name-tooltip"
        css={styles.tooltip}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </Tooltip>
    );
    const firstNameInput = (
      <FirstNameInput
        value={formData.firstName}
        onChange={handleChange}
        required
        isDirty={inputDirty.firstName}
        onBlur={handleBlur}
        isLoading={false}
        errors={errors?.firstName}
      />
    );

    const lastNameInput = (
      <LastNameInput
        value={formData.lastName}
        onChange={handleChange}
        required
        isDirty={inputDirty.lastName}
        onBlur={handleBlur}
        isLoading={false}
        errors={errors?.lastName}
      />
    );

    const desktopLayout = (
      <div css={styles.row}>
        {firstNameInput}
        <Spacer width="oneHalf" />
        {lastNameInput}
        {!isEmerson && nameTooltip}
      </div>
    );

    const mobileLayout = (
      <>
        <div css={styles.row}>
          {firstNameInput}
          {!isEmerson && !isPracOnboardingEnabled && nameTooltip}
        </div>
        <div css={styles.row}>
          {lastNameInput}
          {!isEmerson && !isPracOnboardingEnabled && nameTooltip}
        </div>
      </>
    );

    return phone.greaterThan ? desktopLayout : mobileLayout;
  };

  const onSubmit = e => {
    if (formData.dispensaryName === "") {
      setFormData({
        ...formData,
        dispensaryName: getDefaultDispensaryName(),
      });
    }
    onClick(e);
  };

  const renderSubtitle = () => {
    if (isPracOnboardingEnabled) {
      return (
        <Trans i18nKey={l.practitionerSignUp.PracOnboarding.TellUsAboutYourself}>
          Tell us a bit about yourself to get started. All accounts are{" "}
          <strong css={styles.freeText}>100% free</strong>
        </Trans>
      );
    }

    return isEmerson ? (
      t(l.practitionerSignUp.Emerson.TellUsAboutYourPractice)
    ) : (
      <Trans i18nKey={l.practitionerSignUp.TellUsAboutYourPractice}>
        Tell us a bit about your practice to get started. All accounts are{" "}
        <strong css={styles.freeText}>100% free</strong>
      </Trans>
    );
  };

  const renderDispensaryNameTooltip = () => {
    if (!isEmerson) {
      if (isPracOnboardingEnabled && phoneLarge.lessThan) return null;

      return (
        <Tooltip
          tooltipContent={t(l.practitionerSignUp.DispensaryNameTooltip)}
          css={styles.tooltip}
          data-testid="dispensary-name-tooltip"
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      );
    }
  };

  const renderDesignationSearchTooltip = () => {
    if (isPracOnboardingEnabled && phoneLarge.lessThan) return null;

    return (
      <Tooltip
        tooltipContent={t(l.practitionerSignUp.DesignationSearchTooltip)}
        css={styles.tooltip}
        data-testid="designation-search-tooltip"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </Tooltip>
    );
  };

  return (
    <div css={styles.contentBox}>
      <div css={isPracOnboardingEnabled && styles.headingWrapper}>
        {renderHeader()}
        <Typography css={styles.subTitle}>{renderSubtitle()}</Typography>
      </div>

      <SSOErrorBox ssoErrors={ssoErrors} />

      <FormWrapper style={isPracOnboardingEnabled ? { maxWidth: "100%" } : null}>
        <form onSubmit={onSubmit} css={styles.form} noValidate>
          {renderFirstLastNameFields()}
          <div css={styles.row}>
            <DispensaryNameInput
              value={formData.dispensaryName}
              onChange={handleChange}
              isDirty={inputDirty.dispensaryName}
              onClick={() => setInputDirty(prevState => ({ ...prevState, dispensaryName: true }))}
              placeholder={!isEmerson ? getDefaultDispensaryName() : null}
              onBlur={handleDispensaryNameBlur}
              required={!isEmerson}
            />
            {renderDispensaryNameTooltip()}
          </div>
          <div css={styles.row}>
            <DesignationSearch
              value={formData.practitionerType}
              setValue={handleSetDesignationValue}
              required
              data-e2e="practitioner-type-trigger"
              onBlur={() => handleLogPracTypeField(formData.practitionerType)}
            />
            {renderDesignationSearchTooltip()}
          </div>
          <Spacer height="half" />
          <TermsOfServiceCheckbox
            updateTimestamp={updateTimestamp}
            isChecked={!!formData.tosTimestamp}
          />
          <Spacer height="oneHalf" />
          {renderNextButton()}
        </form>
      </FormWrapper>
    </div>
  );
};

export { PageOne };
